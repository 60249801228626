import React from 'react'

import { useClientOnly } from '~/components/ClientOnly'
import { RoodyEmotion } from '~/types/roody-emotion'

export default function EmptyStateWithRoody(props: {
  title: string
  body: string
  action?: React.ReactNode
  roodyEmotion?: RoodyEmotion
}) {
  const isClient = useClientOnly()

  if (!isClient) {
    return null
  }

  return (
    <div className="grid place-items-center">
      <div className="bg-white rounded-3xl shadow-[0px_8px_24px_rgba(0,_0,_0,_0.08)] p-12 w-[360px]">
        <div className="-mt-32 flex justify-center mb-4">
          <div className="h-40 w-40">
            <img src={props.roodyEmotion || RoodyEmotion.WARNING} alt="Roody" />
          </div>
        </div>
        <h1 className="text-2xl text-app-gray-dark text-center mb-6">{props.title}</h1>

        <p className="text-app-gray-dark text-center">{props.body}</p>

        {props.action && props.action}
      </div>
    </div>
  )
}
